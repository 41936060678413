export const setActiveTenant = (tenantId: string) => {
	localStorage.setItem("activeTenant", tenantId);
};

export const getActiveTenant = () => {
	return localStorage.getItem("activeTenant");
};

export const getRandomColor = () => {
	const letters = "0123456789ABCDEF";
	let color = "#";
	for (let i = 0; i < 6; i++) {
		color += letters[Math.floor(Math.random() * 16)];
	}
	// Check if the color is light, if so, make it dark
	const luminance =
		(0.299 * parseInt(color.slice(1, 3), 16) +
			0.587 * parseInt(color.slice(3, 5), 16) +
			0.114 * parseInt(color.slice(5, 7), 16)) /
		255;
	if (luminance > 0.6) {
		color = "#7f56d9";
	}
	return color;
};

export const hashUserIdToColor = (userId: string) => {
	const colors = [
		"#054F31",
		"3039855",
		"#DC6803",
		"#B54708",
		"#B42318",
		"#447546",
		"#3B7C07",
		"#087443",
		"#107569",
		"#088AB2",
		"#026AA2",
		"#0B4A6F",
		"#6927DA",
		"#4A1FB8",
		"#9F1AB1",
		"#DD2590",
		"#C01048",
		"#F63D68",
		"#F63D68",
		"#FF4405",
		"#BC1B06",
		"#EF6820",
		"#E04F16",
		"#EAAA08",
		"#A15C07",
		"#713B12",
	];

	// Generate a hash code from the userId
	let hashCode = 0;
	for (let i = 0; i < userId.length; i++) {
		hashCode =
			(hashCode << 5) - hashCode + userId.charCodeAt(i);
		hashCode |= 0; // Convert to 32-bit integer
	}

	// Get the index of the color based on the hash code
	const colorIndex = Math.abs(hashCode) % colors.length;

	return colors[colorIndex];
};
export const getNameInitials = (username: string) => {
	const names = username.trim().split(" ");
	if (names.length === 1) {
		return names[0].charAt(0).toUpperCase();
	}
	return (
		names[0].charAt(0) + names[names.length - 1].charAt(0)
	).toUpperCase();
};
