import { createSlice } from "@reduxjs/toolkit";

import type { THammerState } from "../../types/hammer.type";

import { extractHammerQuestions } from "../../utils/hammerUtils";

import {
	fetchAllHammersThunk,
	fetchHammerQuestionsThunk,
	getAnalysisResultsThunk,
	getPricingResultsThunk,
	getStructureCashflowThunk,
	getStructureMetaThunk,
} from "./hammer.thunk";

const initialState: THammerState = {
	meta: {
		data: null,
		loading: false,
		error: null,
	},
	parameters: {
		data: null,
		loading: false,
		error: null,
	},
	cashflow: {
		data: null,
		loading: false,
		error: null,
	},
	analysisResults: {
		data: null,
		loading: false,
		error: null,
	},
	pricingResults: {
		data: null,
		loading: false,
		error: null,
	},
	allAvailableHammers: [],
};

export const HammerSlice = createSlice({
	name: "hammer",
	initialState,
	reducers: {
		setAnalysisResultsToLoading: (state) => {
			state.analysisResults.loading = true;
			state.analysisResults.error = null;
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(getStructureMetaThunk.pending, (state) => {
				state.meta.loading = true;
				state.meta.error = null;
			})
			.addCase(
				getStructureMetaThunk.fulfilled,
				(state, action) => {
					state.meta.loading = false;
					state.meta.data = action.payload;
				}
			)
			.addCase(
				getStructureMetaThunk.rejected,
				(state, action) => {
					state.meta.loading = false;
					state.meta.error = action.payload as any;
				}
			);
		builder
			.addCase(fetchAllHammersThunk.pending, (state) => {
				state.allAvailableHammers = [];
			})
			.addCase(
				fetchAllHammersThunk.fulfilled,
				(state, action) => {
					const data = action.payload as any;

					const hammers = data.map((hammer: any) => {
						return {
							hammerName: hammer.hammer_name,
							hammerId: hammer.hammer_id,
							isFeasible: hammer.is_feasible,
							status: hammer.status,
							uid: hammer.uid,
							tapeId: hammer.tape_id,
						};
					});

					state.allAvailableHammers = hammers;
				}
			)
			.addCase(
				fetchAllHammersThunk.rejected,
				(state, action) => {
					state.allAvailableHammers = [];
				}
			);
		builder
			.addCase(
				fetchHammerQuestionsThunk.pending,
				(state) => {
					state.parameters.loading = true;
					state.parameters.error = null;
				}
			)
			.addCase(
				fetchHammerQuestionsThunk.fulfilled,
				(state, action) => {
					const data = action.payload as any;
					state.parameters.loading = false;
					state.parameters.data = {
						global: extractHammerQuestions(data.global),
						pricing: extractHammerQuestions(data.pricing),
						residual: extractHammerQuestions([
							data.residual,
						])[0],
						tranche: extractHammerQuestions([
							data.tranche,
						])[0],
						hammerType: extractHammerQuestions([
							data.hammer_type,
						])[0],
					};
				}
			)
			.addCase(
				fetchHammerQuestionsThunk.rejected,
				(state, action) => {
					state.parameters.loading = false;
					state.parameters.error = action.payload as any;
				}
			);

		builder
			.addCase(
				getStructureCashflowThunk.pending,
				(state) => {
					state.cashflow.loading = true;
					state.cashflow.error = null;
				}
			)
			.addCase(
				getStructureCashflowThunk.fulfilled,
				(state, action) => {
					const data = action.payload as any;
					state.cashflow.loading = false;
					state.cashflow.data = data;
				}
			)
			.addCase(
				getStructureCashflowThunk.rejected,
				(state, action) => {
					state.cashflow.loading = false;
					state.cashflow.error = action.payload as any;
				}
			);
		builder
			.addCase(getAnalysisResultsThunk.pending, (state) => {
				state.analysisResults.loading = true;
				state.analysisResults.error = null;
			})
			.addCase(
				getAnalysisResultsThunk.fulfilled,
				(state, action) => {
					state.analysisResults.loading = false;
					state.analysisResults.data = action.payload;
				}
			)
			.addCase(
				getAnalysisResultsThunk.rejected,
				(state, action) => {
					state.analysisResults.loading = false;
					state.analysisResults.error =
						action.payload as any;
				}
			);
		builder
			.addCase(getPricingResultsThunk.pending, (state) => {
				state.pricingResults.loading = true;
				state.pricingResults.error = null;
			})
			.addCase(
				getPricingResultsThunk.fulfilled,
				(state, action) => {
					state.pricingResults.loading = false;
					state.pricingResults.data = action.payload;
				}
			)
			.addCase(
				getPricingResultsThunk.rejected,
				(state, action) => {
					state.pricingResults.loading = false;
					state.pricingResults.error =
						action.payload as any;
				}
			);
	},
});

export const HammerSliceActions = HammerSlice.actions;
export const HammerReducer = HammerSlice.reducer;
