import { nanoid } from "@reduxjs/toolkit";

import type { TQuestionObject } from "../types/analysis.type";

export const extractHammerQuestions = (
	data: Array<any> | null
) => {
	if (!data) return [];
	const res: Array<TQuestionObject> = data.map(
		(value: any) => {
			let tempAnswer = value.answer;

			if (typeof value.answer === "object") {
				tempAnswer = JSON.stringify(value.answer);
			}
			return {
				question: value.question,
				questionId: value.question_id,
				answers:
					value.answer_type_info.answer_type ===
					"option_selection"
						? value.answer_type_info.options?.map(
								(option: any) => ({
									litheId: option.lithe_id,
									litheName: option.lithe_name,
								})
							)
						: [],
				answerType: value.answer_type_info.answer_type,
				defaultAnswer: value.answer,
				validations: value.answer_type_info.constraints,
				isGenAIEnabled: value.is_genai_compatible,
				answer: tempAnswer,
				pythonValue: value.python_transformation_code,
				naturalTextValue: value.transform_description,
				categoryId: value.category_id,
				label: value.question,
				isProcessed: value.is_processed,
				uniqueId: `${nanoid() + "::" + value.question_id}::${value.category_id}`,
			};
		}
	);
	return res;
};
