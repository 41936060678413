import type {
	TCreateStructureReqObj,
	THammerQuestionRequestObject,
} from "../types/hammer.type";

import { getEndpoint } from "../endpoints";

import {
	httpServiceInstance,
	type HttpService,
} from "./http.service";

export class HammerService {
	private static readonly instance: HammerService;
	private static http: HttpService;
	private static endpoint: string;
	private static base_url: string;

	constructor() {
		HammerService.http = httpServiceInstance;
		HammerService.endpoint = getEndpoint();
		HammerService.base_url = `${HammerService.endpoint}/api/structure`;
	}

	async getAllAvailableHammers(
		tapeId: string
	): Promise<Array<Record<string, any>>> {
		const url = `${HammerService.base_url}`;
		const config = {
			data: {
				tape_id: tapeId,
			},
		};
		try {
			const { data } = await HammerService.http.post<any>(
				url,
				config
			);
			return data;
		} catch (error: any) {
			throw error.response;
		}
	}

	async createStructure(
		req: TCreateStructureReqObj
	): Promise<any> {
		const url = `${HammerService.base_url}/create/${req.tapeId}`;
		const config = {};
		try {
			const { data } = await HammerService.http.get<any>(
				url,
				config
			);
			return data;
		} catch (error: any) {
			throw error.response;
		}
	}

	async getStructureMeta(
		hammerId: string
	): Promise<Array<Record<string, any>>> {
		const url = `${HammerService.base_url}/${hammerId}/meta`;
		const config = {};
		try {
			const { data } = await HammerService.http.get<any>(
				url,
				config
			);
			return data;
		} catch (error: any) {
			throw error.response;
		}
	}

	async getStructureCashflow(
		hammerId: string
	): Promise<Array<Record<string, any>>> {
		const url = `${HammerService.base_url}/${hammerId}/points`;
		const config = {};
		try {
			const { data } = await HammerService.http.get<any>(
				url,
				config
			);
			return data;
		} catch (error: any) {
			throw error.response;
		}
	}

	async getHammerQuestions(
		hammerId: string
	): Promise<Array<Record<string, any>>> {
		const url = `${HammerService.base_url}/${hammerId}/tranche_specs/questions`;
		const config = {};
		try {
			const { data } = await HammerService.http.get<any>(
				url,
				config
			);
			return data;
		} catch (error: any) {
			throw error.response;
		}
	}

	async updateHammerParameters(
		requestObject: THammerQuestionRequestObject
	): Promise<any> {
		const url = `${HammerService.base_url}/${requestObject.structureId}/tranche_specs/questions`;
		const config = {
			data: {
				question_id: requestObject.questionId,
				question_category_id:
					requestObject.questionCategoryId,
				user_input: requestObject.userInput,
				user_input_type: requestObject.userInputType,
			},
		};
		try {
			const { data } = await HammerService.http.post<any>(
				url,
				config
			);
			return data;
		} catch (error: any) {
			throw error.response;
		}
	}

	async getPricingQuestions(
		hammerId: string
	): Promise<Array<Record<string, any>>> {
		const url = `${HammerService.base_url}/${hammerId}/pricing/questions`;
		const config = {};
		try {
			const { data } = await HammerService.http.get<any>(
				url,
				config
			);
			return data;
		} catch (error: any) {
			throw error.response;
		}
	}

	async updatePricingParameters(
		requestObject: THammerQuestionRequestObject
	): Promise<any> {
		const url = `${HammerService.base_url}/${requestObject.structureId}/pricing/questions`;
		const config = {
			data: {
				question_id: requestObject.questionId,
				question_category_id:
					requestObject.questionCategoryId,
				user_input: requestObject.userInput,
				user_input_type: requestObject.userInputType,
			},
		};
		try {
			const { data } = await HammerService.http.post<any>(
				url,
				config
			);
			return data;
		} catch (error: any) {
			throw error.response;
		}
	}

	async processHammer(hammerId: string): Promise<any> {
		const url = `${HammerService.base_url}/${hammerId}/tranche_specs`;
		const config = {
			data: {
				is_step_confirmed: false,
			},
		};
		try {
			const { data } = await HammerService.http.post<any>(
				url,
				config
			);
			return data;
		} catch (error: any) {
			throw error.response;
		}
	}

	async confirmStructure(hammerId: string): Promise<any> {
		const url = `${HammerService.base_url}/${hammerId}/confirm`;
		const config = {
			data: {
				is_step_confirmed: true,
			},
		};
		try {
			const { data } = await HammerService.http.post<any>(
				url,
				config
			);
			return data;
		} catch (error: any) {
			throw error.response;
		}
	}

	async getAnalysisResults(hammerId: string): Promise<any> {
		const url = `${HammerService.base_url}/${hammerId}/tranche_specs/results`;
		const config = {};
		try {
			const { data } = await HammerService.http.get<any>(
				url,
				config
			);
			return data;
		} catch (error: any) {
			throw error.response;
		}
	}

	async resetHammerQuestions(
		hammerId: string
	): Promise<any> {
		const url = `${HammerService.base_url}/${hammerId}/tranche_specs/reset`;
		const config = {};
		try {
			const { data } = await HammerService.http.get<any>(
				url,
				config
			);
			return data;
		} catch (error: any) {
			throw error.response;
		}
	}

	async processPricing(hammerId: string): Promise<any> {
		const url = `${HammerService.base_url}/${hammerId}/pricing`;
		const config = {
			data: {
				is_step_confirmed: false,
			},
		};
		try {
			const { data } = await HammerService.http.post<any>(
				url,
				config
			);
			return data;
		} catch (error: any) {
			throw error.response;
		}
	}

	async getPricingResults(hammerId: string): Promise<any> {
		const url = `${HammerService.base_url}/${hammerId}/pricing/results`;
		const config = {};
		try {
			const { data } = await HammerService.http.get<any>(
				url,
				config
			);
			return data;
		} catch (error: any) {
			throw error.response;
		}
	}

	async saveStructure({
		structureId,
		name,
		tapeId,
	}: {
		structureId: string;
		name: string;
		tapeId: string;
	}): Promise<any> {
		const url = `${HammerService.base_url}/${structureId}/save`;
		const config = {
			data: {
				name,
				tape_id: tapeId,
			},
		};
		try {
			const { data } = await HammerService.http.post<any>(
				url,
				config
			);
			return data;
		} catch (error: any) {
			throw error.response;
		}
	}
}
