import { createAsyncThunk } from "@reduxjs/toolkit";

import type { THammerQuestionRequestObject } from "../../types/hammer.type";

import { services } from "../../services";

export const getStructureMetaThunk = createAsyncThunk(
	"hammer/getStructureMetaThunk",
	async (tapeId: string, { dispatch, rejectWithValue }) => {
		try {
			const data =
				await services.hammerService.getStructureMeta(
					tapeId
				);
			return data;
		} catch (error: any) {
			console.error("redux error", error);
			return rejectWithValue({
				...error.response.data,
				msg: `Error occurred: ${error.response.data.msg}`,
			});
		}
	}
);

export const getStructureCashflowThunk = createAsyncThunk(
	"hammer/getStructureCashflowThunk",
	async (tapeId: string, { dispatch, rejectWithValue }) => {
		try {
			const data =
				await services.hammerService.getStructureCashflow(
					tapeId
				);
			return data;
		} catch (error: any) {
			console.error("redux error", error);
			return rejectWithValue({
				...error.response.data,
				msg: `Error occurred: ${error.response.data.msg}`,
			});
		}
	}
);

export const fetchAllHammersThunk = createAsyncThunk(
	"hammer/fetchAllHammersThunk",
	async (tapeId: string, { dispatch, rejectWithValue }) => {
		try {
			const data =
				await services.hammerService.getAllAvailableHammers(
					tapeId
				);
			return data;
		} catch (error: any) {
			console.error("redux error", error);
			return rejectWithValue({
				...error.response.data,
				msg: `Error occurred: ${error.response.data.msg}`,
			});
		}
	}
);

export const fetchHammerQuestionsThunk = createAsyncThunk(
	"hammer/fetchHammerQuestionsThunk",
	async (
		hammerId: string,
		{ dispatch, rejectWithValue }
	) => {
		try {
			const data =
				await services.hammerService.getHammerQuestions(
					hammerId
				);
			return data;
		} catch (error: any) {
			console.error("redux error", error);
			return rejectWithValue({
				...error.response.data,
				msg: `Error occurred: ${error.response.data.msg}`,
			});
		}
	}
);

export const fetchPricingQuestionsThunk = createAsyncThunk(
	"hammer/fetchPricingQuestionsThunk",
	async (
		hammerId: string,
		{ dispatch, rejectWithValue }
	) => {
		try {
			const data =
				await services.hammerService.getPricingQuestions(
					hammerId
				);
			return data;
		} catch (error: any) {
			console.error("redux error", error);
			return rejectWithValue({
				...error.response.data,
				msg: `Error occurred: ${error.response.data.msg}`,
			});
		}
	}
);

export const updatePricingParametersThunk =
	createAsyncThunk(
		"hammer/updatePricingParametersThunk",
		async (
			requestObject: THammerQuestionRequestObject,
			{ dispatch, rejectWithValue }
		) => {
			try {
				const data =
					await services.hammerService.updatePricingParameters(
						requestObject
					);
				return data;
			} catch (error: any) {
				console.error("redux error", error);
				return rejectWithValue({
					...error.response.data,
					msg: `Error occurred: ${error.response.data.msg}`,
				});
			}
		}
	);

export const processHammerThunk = createAsyncThunk(
	"hammer/processHammerThunk",
	async (
		hammerId: string,
		{ dispatch, rejectWithValue }
	) => {
		try {
			const data =
				await services.hammerService.processHammer(
					hammerId
				);
			return data;
		} catch (error: any) {
			console.error("redux error", error);
			return rejectWithValue({
				...error.response.data,
				msg: `Error occurred: ${error.response.data.msg}`,
			});
		}
	}
);

export const getAnalysisResultsThunk = createAsyncThunk(
	"hammer/getAnalysisResultsThunk",
	async (
		hammerId: string,
		{ dispatch, rejectWithValue }
	) => {
		try {
			const data =
				await services.hammerService.getAnalysisResults(
					hammerId
				);
			return data;
		} catch (error: any) {
			console.error("redux error", error);
			return rejectWithValue({
				...error.response.data,
				msg: `Error occurred: ${error.response.data.msg}`,
			});
		}
	}
);

export const getPricingResultsThunk = createAsyncThunk(
	"hammer/getPricingResultsThunk",
	async (
		hammerId: string,
		{ dispatch, rejectWithValue }
	) => {
		try {
			const data =
				await services.hammerService.getPricingResults(
					hammerId
				);
			return data;
		} catch (error: any) {
			console.error("redux error", error);
			return rejectWithValue({
				...error.response.data,
				msg: `Error occurred: ${error.response.data.msg}`,
			});
		}
	}
);
