export type TLitheSliceState = {
	meta: TSliceStateVariableBlueprint<TLitheMeta | null>;
	specsQuestions: TSliceStateVariableBlueprint<Array<TLitheQuestion> | null>;
	dataVizQuestions: TSliceStateVariableBlueprint<Array<TLitheQuestion> | null>;
	dataVizResults: TSliceStateVariableBlueprint<TLitheResultsBlueprint | null>;
	cashflowVizQuestions: TSliceStateVariableBlueprint<Array<TLitheQuestion> | null>;
	cashflowVizResults: TSliceStateVariableBlueprint<TLitheResultsBlueprint | null>;
	probsQuestions: TSliceStateVariableBlueprint<Array<TLitheQuestion> | null>;
	probsResults: TSliceStateVariableBlueprint<TLitheResultsBlueprint | null>;
	approvalInfo: TSliceStateVariableBlueprint<TLitheResultsBlueprint | null>;
	rfSpecsQuestions: TSliceStateVariableBlueprint<Array<TLitheQuestion> | null>;
	rawProbData: TSliceStateVariableBlueprint<any>;
};

export type TLitheCreationData = {
	litheName: string;
	assetClassId: string;
	litheCreationType: LitheCreationTypeEnum;
	litheScope: "global" | "tenant";
};

export enum LitheCreationTypeEnum {
	DATASET = "dataset",
	MANUAL = "manual",
	PROGRAMMATIC = "programmatic",
}

export enum LitheScopeEnum {
	GLOBAL = "global",
	TENANT = "tenant",
}

export type TLitheMeta = TLitheCreationData & {
	litheId: string;
	allSteps: Array<LitheStepEnum>;
	currentStep: LitheStepEnum;
};

export enum LitheStepEnum {
	SPECS = "specs",
	DATAVIZ = "data_viz",
	CASHFLOWVIZ = "cashflow_viz",
	PROBS = "probs",
	RFSPECS = "rf_specs",
	RAWPROBS = "raw_probs",
	APPROVAL = "approval",
	LITHEAPPROVED = "lithe_approved",
}

export type TSliceStateVariableBlueprint<T> = {
	data: T;
	loading: boolean;
	error: any;
};

export type TLitheResultsBlueprint = {
	output: any;
	status: string;
};

export type TLitheQuestionRequestObject = {
	litheId: string;
	questionId: string;
	questionCategoryId: string;
	userInput: string;
	userInputType:
		| "natural_language"
		| "python_code"
		| "option_selection";
};

export type TLitheQuestion = {
	question: string;
	questionId: string;
	answers: any;
	categoryId?: string;
	answerType: any;
	answer: any;
	defaultAnswer: any;
	validations?: any;
	isGenAIEnabled: boolean;
	pythonValue: string;
	naturalTextValue: string;
	label?: string;
	isProcessed: boolean;
	isEditable?: boolean;
};

export type TLitheItem = {
	id: string;
	name: string;
	uploadTime: string;
	userId: string;
	tenant: string;
	status: string;
	assetClass: string;
	createdBy?: string;
	lithe_creation_type?: string;
};

export type TLitheSearchResponseProps = {
	lithes: Array<TLitheItem>;
	count: number;
	error?: any;
};
