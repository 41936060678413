import { Suspense, lazy } from "react";
import {
	Navigate,
	Outlet,
	Route,
	Routes,
} from "react-router-dom";

import { AuthenticationCheck } from "./AuthenticationCheck";
import AnalysisOutlet from "./CaliperOutlet";
import { AnalysisContextProvider } from "./contexts/analysisContext";
import { LitheContextProvider } from "./contexts/litheContext";
import { SanitizationContextProvider } from "./contexts/sanitizationContext";
import { PasswordSetCheck } from "./PasswordSetCheck";
import { useAppSelector } from "./redux/store";
import { LoaderContainer } from "./shared/Loader";

const Login = lazy(
	async () => await import("./pages/Login")
);
const MultiFactor = lazy(
	async () => await import("./pages/MultiFactor")
);
const SanitizeTape = lazy(
	async () => await import("./pages/SanitizeTape")
);
const CreatePassword = lazy(
	async () => await import("./pages/CreatePassword")
);
const LandingPage = lazy(
	async () => await import("./pages/LandingPage")
);

const TapeSearch = lazy(
	async () => await import("./pages/TapeSearch")
);

const LitheSearch = lazy(
	async () => await import("./pages/LitheSearch")
);

const AnalysisPage = lazy(
	async () => await import("./pages/Analysis")
);

const LithePage = lazy(
	async () => await import("./pages/Lithe")
);

const UserManagement = lazy(
	async () => await import("./pages/UserManagement")
);

const HammerSearch = lazy(
	async () => await import("./pages/HammerSearch")
);

const HammerScreen = lazy(
	async () =>
		await import("./components/hammer/HammerScreen")
);

export const AppRouter = () => {
	const { authStatus, isPasswordSet } = useAppSelector(
		(state) => state.user
	);

	return (
		<Routes>
			<Route
				path="/"
				element={<AuthenticationCheck />}
			>
				<Route
					path="/"
					element={<PasswordSetCheck />}
				>
					<Route
						path="/users"
						element={
							<Suspense
								fallback={<LoaderContainer width={50} />}
							>
								<UserManagement />
							</Suspense>
						}
					/>
					<Route
						path="/analysis/"
						element={<Outlet />}
					>
						<Route
							path=":tapeId"
							element={
								<Suspense
									fallback={<LoaderContainer width={50} />}
								>
									<AnalysisContextProvider>
										<AnalysisPage />
									</AnalysisContextProvider>
								</Suspense>
							}
						/>
						<Route
							path=":tapeId/structures"
							element={
								<Suspense
									fallback={<LoaderContainer width={50} />}
								>
									<HammerSearch />
								</Suspense>
							}
						/>
						<Route
							path=":tapeId/structures/:structureId"
							element={
								<Suspense
									fallback={<LoaderContainer width={50} />}
								>
									<HammerScreen />
								</Suspense>
							}
						/>
					</Route>
					<Route
						path="/library"
						element={
							<Suspense
								fallback={<LoaderContainer width={50} />}
							>
								<TapeSearch />
							</Suspense>
						}
					/>
					<Route
						path="/lithe-library"
						element={
							<Suspense
								fallback={<LoaderContainer width={50} />}
							>
								<LitheSearch />
							</Suspense>
						}
					/>
					<Route
						path="/lithe/:method/:step/:litheId"
						element={
							<Suspense
								fallback={<LoaderContainer width={50} />}
							>
								<LitheContextProvider>
									<LithePage />
								</LitheContextProvider>
							</Suspense>
						}
					/>
					<Route
						path="/sanitize/:step/:tapeId"
						element={
							<Suspense
								fallback={<LoaderContainer width={50} />}
							>
								<SanitizationContextProvider>
									<SanitizeTape />
								</SanitizationContextProvider>
							</Suspense>
						}
					/>
					<Route
						path="/"
						element={
							<Suspense
								fallback={<LoaderContainer width={50} />}
							>
								<LandingPage />
							</Suspense>
						}
					/>
				</Route>

				<Route
					path="/create-password"
					element={
						isPasswordSet ? (
							<Navigate to={"/"} />
						) : (
							<Suspense
								fallback={<LoaderContainer width={50} />}
							>
								<CreatePassword />
							</Suspense>
						)
					}
				/>
			</Route>

			<Route
				path="/login"
				element={
					authStatus.isLoggedIn ? (
						<Navigate to={"/"} />
					) : (
						<Suspense
							fallback={<LoaderContainer width={50} />}
						>
							<Login />
						</Suspense>
					)
				}
			/>

			<Route
				path="/multi-factor-verification"
				element={
					<Suspense
						fallback={<LoaderContainer width={50} />}
					>
						<MultiFactor />
					</Suspense>
				}
			/>

			<Route
				path="*"
				element={<div>404</div>}
			/>
		</Routes>
	);
};
