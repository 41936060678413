import { configureStore } from "@reduxjs/toolkit";
import {
	type TypedUseSelectorHook,
	useDispatch,
	useSelector,
} from "react-redux";

import { AnalysisReducer } from "./analysis/analysis.slice";
import { HammerReducer } from "./hammer/hammer.slice";
import { LitheReducer } from "./lithe/lithe.slice";
import { SanitizationReducer } from "./sanitization/sanitization.slice";
import { TapeReducer } from "./tapes/tapes.slice";
import { TenantReducer } from "./tenants/tenant.slice";
import { UserAuthReducer } from "./user/user.slice";

/**
 * The Redux store configuration.
 *
 * @remarks
 * The store is responsible for holding the application state and dispatching actions to update the state.
 */
export const store = configureStore({
	reducer: {
		user: UserAuthReducer,
		analysis: AnalysisReducer,
		tenant: TenantReducer,
		tapes: TapeReducer,
		sanitization: SanitizationReducer,
		lithe: LitheReducer,
		hammer: HammerReducer,
	},
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type TRootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type TAppDispatch = typeof store.dispatch;

export const useAppDispatch: () => TAppDispatch =
	useDispatch;
export const useAppSelector: TypedUseSelectorHook<TRootState> =
	useSelector;
