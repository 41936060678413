import { createSlice } from "@reduxjs/toolkit";

import type {
	TLitheMeta,
	TLitheSliceState,
} from "../../types/lithe.type";

import { extractQuestionsLithe } from "../../utils/litheUtils";

import {
	fetchLitheCashflowVizQuestionsThunk,
	fetchLitheDataVizQuestionsThunk,
	fetchLitheProbsQuestionsThunk,
	fetchLitheSpecsQuestionsThunk,
	fetchLitheThunk,
	fetchLitheProbsResultsThunk,
	fetchLitheRFSpecsQuestionsThunk,
	fetchLitheApprovalInfo,
	fetchLitheRawProbData,
} from "./lithe.thunk";

const initialState: TLitheSliceState = {
	meta: {
		data: null,
		loading: false,
		error: null,
	},
	specsQuestions: {
		data: null,
		loading: false,
		error: null,
	},
	dataVizQuestions: {
		data: null,
		loading: false,
		error: null,
	},
	dataVizResults: {
		data: null,
		loading: false,
		error: null,
	},
	cashflowVizQuestions: {
		data: null,
		loading: false,
		error: null,
	},
	cashflowVizResults: {
		data: null,
		loading: false,
		error: null,
	},
	probsQuestions: {
		data: null,
		loading: false,
		error: null,
	},
	probsResults: {
		data: null,
		loading: false,
		error: null,
	},
	approvalInfo: {
		data: null,
		loading: false,
		error: null,
	},
	rfSpecsQuestions: {
		data: null,
		loading: false,
		error: null,
	},
	rawProbData: {
		data: null,
		loading: false,
		error: null,
	},
};

const resetStates = (keys: Array<string>, state: any) => {
	keys.forEach((key) => {
		if (state[key].data !== null) {
			state[key] =
				initialState[key as keyof TLitheSliceState];
		}
	});
};

export const LitheSlice = createSlice({
	name: "tapes",
	initialState,
	reducers: {
		updateLitheDataVizResults: (state, action) => {
			state.dataVizResults.data = action.payload;
		},
		updateLitheDataVizResultsError: (state, action) => {
			state.dataVizResults.error = action.payload;
		},
		handleDataVizResultsLoading: (state, action) => {
			state.dataVizResults.loading = action.payload;
		},
		updateCashflowVizResults: (state, action) => {
			state.cashflowVizResults.data = action.payload;
		},
		updateLitheCashflowVizResultsError: (state, action) => {
			state.cashflowVizResults.error = action.payload;
		},
		handleCashflowVizResultsLoading: (state, action) => {
			state.cashflowVizResults.loading = action.payload;
		},
		updateProbsResults: (state, action) => {
			state.probsResults.data = action.payload;
		},
		updateProbsResultsError: (state, action) => {
			state.probsResults.error = action.payload;
		},
		handleProbsResultsLoading: (state, action) => {
			state.probsResults.loading = action.payload;
		},
		resetLitheState: (state) => {
			state = initialState;
		},
		resetLitheDataVizStep: (state) => {
			state.dataVizQuestions = {
				data: null,
				loading: false,
				error: null,
			};
			state.dataVizResults = {
				data: null,
				loading: false,
				error: null,
			};
		},
		resetLitheCashflowVizStep: (state) => {
			state.cashflowVizQuestions = {
				data: null,
				loading: false,
				error: null,
			};
			state.cashflowVizResults = {
				data: null,
				loading: false,
				error: null,
			};
		},
		resetLitheProbsStep: (state) => {
			state.probsQuestions = {
				data: null,
				loading: false,
				error: null,
			};
		},
		resetLitheRawProbStep: (state) => {
			state.rawProbData = {
				data: null,
				loading: false,
				error: null,
			};
		},

		resetLitheRFSpecsStep: (state) => {
			state.rfSpecsQuestions = {
				data: null,
				loading: false,
				error: null,
			};
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(fetchLitheThunk.pending, (state) => {
				state.meta = {
					...state.meta,
					loading: true,
					error: null,
				};
			})
			.addCase(
				fetchLitheThunk.fulfilled,
				(state, action) => {
					const data: TLitheMeta = {
						litheId: action.payload.lithe_id,
						litheName: action.payload.lithe_name,
						assetClassId: "",
						litheCreationType: action.payload.lithe_type,
						litheScope: action.payload.lithe_scope,
						allSteps: action.payload.all_steps,
						currentStep: action.payload.current_step,
					};
					state.meta = {
						data,
						loading: false,
						error: null,
					};
				}
			)
			.addCase(
				fetchLitheThunk.rejected,
				(state, action) => {
					state.meta.error = action.payload;
					state.meta.loading = false;
				}
			);
		builder
			.addCase(
				fetchLitheSpecsQuestionsThunk.pending,
				(state) => {
					state.specsQuestions.loading = true;
					state.specsQuestions.error = null;
					const keys = [
						"dataVizQuestions",
						"dataVizResults",
						"cashflowVizQuestions",
						"cashflowVizResults",
						"probsQuestions",
						"probsResults",
						"approvalInfo",
						"rfSpecsQuestions",
					];
					resetStates(keys, state);
				}
			)
			.addCase(
				fetchLitheSpecsQuestionsThunk.fulfilled,
				(state, action) => {
					const questions = extractQuestionsLithe(
						action.payload
					);
					state.specsQuestions.data = questions;
					state.specsQuestions.loading = false;
					state.specsQuestions.error = null;
				}
			)
			.addCase(
				fetchLitheSpecsQuestionsThunk.rejected,
				(state, action) => {
					state.specsQuestions.error = action.payload;
					state.specsQuestions.loading = false;
				}
			);
		builder
			.addCase(
				fetchLitheDataVizQuestionsThunk.pending,
				(state) => {
					state.dataVizQuestions.loading = true;
					state.dataVizQuestions.error = null;
					const keys = [
						"cashflowVizQuestions",
						"cashflowVizResults",
						"probsQuestions",
						"probsResults",
						"approvalInfo",
					];
					resetStates(keys, state);
				}
			)
			.addCase(
				fetchLitheDataVizQuestionsThunk.fulfilled,
				(state, action) => {
					const questions = extractQuestionsLithe(
						action.payload
					);
					state.dataVizQuestions.data = questions;
					state.dataVizQuestions.loading = false;
					state.dataVizQuestions.error = null;
				}
			)
			.addCase(
				fetchLitheDataVizQuestionsThunk.rejected,
				(state, action) => {
					state.dataVizQuestions.error = action.payload;
					state.dataVizQuestions.loading = false;
				}
			);
		builder
			.addCase(
				fetchLitheCashflowVizQuestionsThunk.pending,
				(state) => {
					state.cashflowVizQuestions = {
						data: null,
						loading: true,
						error: null,
					};
					const keys = [
						"probsQuestions",
						"probsResults",
						"approvalInfo",
					];
					resetStates(keys, state);
				}
			)
			.addCase(
				fetchLitheCashflowVizQuestionsThunk.fulfilled,
				(state, action) => {
					const questions = extractQuestionsLithe(
						action.payload
					);
					state.cashflowVizQuestions.data = questions;
					state.cashflowVizQuestions.loading = false;
					state.cashflowVizQuestions.error = null;
				}
			)
			.addCase(
				fetchLitheCashflowVizQuestionsThunk.rejected,
				(state, action) => {
					state.cashflowVizQuestions.error = action.payload;
					state.cashflowVizQuestions.loading = false;
				}
			);
		builder
			.addCase(
				fetchLitheProbsQuestionsThunk.pending,
				(state) => {
					state.probsQuestions = {
						data: null,
						loading: true,
						error: null,
					};
					const keys = ["approvalInfo"];
					resetStates(keys, state);
				}
			)
			.addCase(
				fetchLitheProbsQuestionsThunk.fulfilled,
				(state, action) => {
					const questions = extractQuestionsLithe(
						action.payload
					);
					state.probsQuestions.data = questions;
					state.probsQuestions.loading = false;
					state.probsQuestions.error = null;
				}
			)
			.addCase(
				fetchLitheProbsQuestionsThunk.rejected,
				(state, action) => {
					state.probsQuestions.error = action.payload;
					state.probsQuestions.loading = false;
				}
			);
		builder
			.addCase(
				fetchLitheProbsResultsThunk.pending,
				(state) => {
					state.probsResults.data = null;
					state.probsResults.loading = true;
					state.probsResults.error = null;
				}
			)
			.addCase(
				fetchLitheProbsResultsThunk.fulfilled,
				(state, action) => {
					state.probsResults.data = action.payload;
					state.probsResults.loading = false;
					state.probsResults.error = null;
				}
			)
			.addCase(
				fetchLitheProbsResultsThunk.rejected,
				(state, action) => {
					state.probsResults.error = action.payload;
					state.probsResults.loading = false;
				}
			);
		builder
			.addCase(
				fetchLitheRFSpecsQuestionsThunk.pending,
				(state) => {
					state.rfSpecsQuestions.data = null;
					state.rfSpecsQuestions.loading = true;
					state.rfSpecsQuestions.error = null;
					const keys = ["approvalInfo", "rawProbData"];
					resetStates(keys, state);
				}
			)
			.addCase(
				fetchLitheRFSpecsQuestionsThunk.fulfilled,
				(state, action) => {
					const questions = extractQuestionsLithe(
						action.payload
					);
					state.rfSpecsQuestions.data = questions;
					state.rfSpecsQuestions.loading = false;
					state.rfSpecsQuestions.error = null;
				}
			)
			.addCase(
				fetchLitheRFSpecsQuestionsThunk.rejected,
				(state, action) => {
					state.rfSpecsQuestions.error = action.payload;
					state.rfSpecsQuestions.loading = false;
				}
			);
		builder
			.addCase(fetchLitheApprovalInfo.pending, (state) => {
				state.approvalInfo.data = null;
				state.approvalInfo.loading = true;
				state.approvalInfo.error = null;
			})
			.addCase(
				fetchLitheApprovalInfo.fulfilled,
				(state, action) => {
					state.approvalInfo.data = action.payload;
					state.approvalInfo.loading = false;
					state.approvalInfo.error = null;
				}
			)
			.addCase(
				fetchLitheApprovalInfo.rejected,
				(state, action) => {
					state.approvalInfo.error = action.payload;
					state.approvalInfo.loading = false;
				}
			);
		builder
			.addCase(fetchLitheRawProbData.pending, (state) => {
				state.rawProbData.data = null;
				state.rawProbData.loading = true;
				state.rawProbData.error = null;
				const keys = ["approvalInfo"];
				resetStates(keys, state);
			})
			.addCase(
				fetchLitheRawProbData.fulfilled,
				(state, action) => {
					state.rawProbData.data = action.payload;
					state.rawProbData.loading = false;
					state.rawProbData.error = null;
				}
			)
			.addCase(
				fetchLitheRawProbData.rejected,
				(state, action) => {
					state.rawProbData.error = action.payload;
					state.rawProbData.loading = false;
				}
			);
	},
});

export const LitheSliceActions = LitheSlice.actions;
export const LitheReducer = LitheSlice.reducer;
